.filePreview {
  position: relative;
  position: relative;
  min-width: 200px;
  max-width: 300px;
  max-height: 150px;
  width: 150px;
  height: 150px;
  border: 2px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileUploadContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadButton {
  margin-bottom: 16px;
}

.filePreviewContainer {
  width: 100%;
  overflow-x: auto;
  padding: 10px;
}

.filePreviewWrapper {
  display: flex;
  gap: 10px; // Add some space between previews
}

.filePreview img,
.filePreview video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.removeButton {
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: var(--white);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 28px;
  height: 28px;
  padding: 4px;
}
