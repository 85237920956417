.header-nav {
  z-index: 1000;
  height: 50px;
  color: var(--black);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: calc(100% - 364px);
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    padding: 0 20px;
  }
}

.headerCollapsed {
  width: calc(100% - 200px);
  padding: 0 75px;
  @media (max-width: 768px) {
    width: calc(100% - 16px);
    padding: 0 8px;
  }
}

.rightContainer {
  display: flex;
  gap: 25px;
  align-items: center;
  @media (max-width: 768px) {
    gap: 4px;
  }
}

.leftContainer {
  font-size: 16px;
  display: flex;
  font-weight: 700;
  gap: 8px;
  align-items: center;
  @media (max-width: 768px) {
    gap: 4px;
  }
}

.header-date {
  span {
    @media (max-width: 768px) {
      display: block;
    }
  }
}

.showWhiteTitle {
  .leftContainer {
    @media (max-width: 768px) {
      color: #fff;
    }
  }
}
