.nudgeItem {
  @media (min-width: 768px) {
    width: 380px;
  }
  &__content {
    padding: 16px;
  }
  &__price_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4px;
  }
  &__price {
    color: var(--Platinum-Success-900, #509854);
    font-size: 32.2px;
    font-weight: 500;
    line-height: 46px;
    letter-spacing: 0.23px;
  }
  &__text {
    color: #000;
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  &__btn_container {
    display: flex;
    justify-content: center;
    padding: 16px;
    gap: 10px;
    align-items: center;
  }

  .cancel_btn {
    border-radius: 8px;
    padding: 10px;
    border: 1px solid var(--primary-color, #fc5a5a);
    color: var(--primary-color, #fc5a5a);
    width: 100%;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .no_btn {
    border-radius: 8px;
    padding: 10px;
    background: var(--primary-color, #fc5a5a);
    color: var(--white, #fff);
    width: 100%;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}

.nudgeGif {
  width: 100px;
  height: 100px;
}
