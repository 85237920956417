.presc-container {
  border-radius: 8px;
  background: #d9d9d9;
  position: relative;
  padding-bottom: 64px;
  margin: 0 16px;

  .container-header {
    background: #818589;
    color: white;
    font-size: 15px;
    font-weight: 600;
    border-radius: 10px 10px 0 0;
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .prescription {
    border-radius: 8px;
    background: #d9d9d9;
    height: 266px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 16px;
    padding: 16px;
    @media (min-width: 768px) {
      height: 486px;
    }
  }

  .polaris-prescription {
    @media (min-width: 768px) {
      height: 300px;
    }
  }

  .prescription img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }

  .prx-validity-dropbox {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 8px;
  }

  .polaris-prx-validity-dropbox {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
  }

  .zoom-modal-footer {
    bottom: 8px;
    left: 30%;
    position: absolute;
    display: flex;
    gap: 10px;
    width: fit-content;
    width: -moz-fit-content;
    max-width: 100%;
    margin: auto;
    overflow-x: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .zoom-modal-footer-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 8px;
    border: 2px solid #efefef;
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  .zoom-modal-footer-image img {
    width: 100%;
    height: auto;
    max-height: 64px;
    border-radius: 8px;
    object-fit: contain;
    @media (max-width: 768px) {
      max-height: 24px;
    }
  }

  .zoom-modal-footer-image-active {
    border: 2px solid #6d7478;
    width: 100px;
    height: 64px;
    @media (max-width: 768px) {
      width: 56px;
      height: 24px;
    }
  }
}
