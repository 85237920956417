.confirmDialog {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 250px;
}

.filtersContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}

.datesContainer {
  display: flex;
  gap: 10px;
}

.refreshIcon {
  width: 24px;
  height: 24px;
  border: 1px solid var(--Platinum-Dark-grey);
  background-color: var(--grey-2);
  border-radius: 4px;
  margin-top: 8px;
  cursor: pointer;
  :hover {
    background-color: var(--activity-light-grey-tex);
  }
}

.Count {
  font-size: 14px;
  font-weight: 500;
}
