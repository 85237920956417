.status_cont {
  padding: 12px 0;
  &__btn_container {
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

.buy_steps {
  width: max-content;
}

.buy_steps:nth-last-of-type(2) .dashed_step_border {
  display: none;
}

.content {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.cancelled_content {
  color: var(--primary-color, #fc5a5a);
}

.status_icon {
  margin-top: 2px;
}

.dashed_step_border {
  font-weight: 700;
  height: 29px;
  display: flex;
  align-items: center;
  border-left: 2px solid;
  margin: -16px 0 2px 8px;
}

.timestamps {
  font-weight: 500;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

.date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.accordion {
  &__expand_icon {
    color: var(--black, #000);
  }

  &__btn_container {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &__cancel_button {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor: pointer;
    color: var(--black, #000);
    background-color: unset;
    border: none;
    padding: 0;
  }

  &__cancel_button:hover {
    color: var(--primary-color, #fc5a5a);
  }

  &__disabled {
    color: var(--Disabled-grey, #9f9f9f);
    cursor: not-allowed;
  }

  &__disabled:hover {
    color: var(--Disabled-grey, #9f9f9f);
  }

  &__tooltip {
    &__message {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: var(--black, #000);
    }

    &__icon {
      color: var(--Platinum-Dark-grey, #6d7478);
      cursor: pointer;
    }
  }
}

.reorder_btn {
  margin-top: 12px;
}

.green_class {
  color: #219653;
}

.grey_class {
  color: #525252;
}

.grey_line_class {
  color: #cdd1d4;
}
