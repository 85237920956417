.coupon_section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border: 1px solid #e0e0e0;
  padding: 10px;
  border-radius: 5px;
}

.coupon_text {
  font-size: 14px;
}
