.scanCode {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.details_container {
  padding: 12px 0;
  background-color: var(--white, #fff);
  border-radius: 8px;
}

.order_info {
  border-radius: 8px;
  border: 1px solid var(--Flexipill-Grey-100, #e8e8e8);
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  &__title {
    color: #6d7478;
  }
  &__value {
    font-weight: 500;
  }
}

.accordion {
  border: 1px solid #e8e8e8;
}
