.contentContainer {
  padding: 0px;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 16px;

  &__heading {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
  }
}

.uploadLabel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  font-size: 12px;
  font-weight: normal;
  border: 1px solid #000;
  border-radius: 8px;
  padding: 4px;
}

.uploadedFiles {
  margin-top: 4px;
  padding: 10px 12px 0 0;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  justify-content: center;
  overflow: scroll;
  scrollbar-width: none;

  &__heading {
    font-weight: normal;
    margin-bottom: -6px;
  }

  &__item {
    cursor: pointer;
    position: relative;
  }

  &__image {
    background-color: #000;
    border-radius: 8px;
    object-fit: contain;
    width: 45px;
    height: 45px;
  }
}

.uploadedFilesCart {
  margin-top: 4px;
  padding-top: 16px;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  overflow: scroll;
  scrollbar-width: none;
}

.uploadContainer {
  border-radius: 8px;
  background: linear-gradient(180deg, #fdebeb 0%, #fff 116.88%);
}

.uploadHeader {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #fddbdb;

  hr {
    width: 2px;
    height: 20px;
    margin: 0 10px;
    background: #f44d4d;
  }
}

.uploadContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 0 12px;
}

.youngDoctor {
  height: 172px;
  width: auto;
}

.save_btn {
  position: absolute;
  bottom: 16px;
  width: calc(100% - 32px);
}

.info_text {
  font-size: 14px;
  line-height: 18px;
  display: flex;
  gap: 4px;
  margin: 16px 0;

  &__icon {
    margin-top: 4px;
  }
}
