.edit_details {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  gap: 8px;

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;

    &__name {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }

    &__link {
      color: var(--primary-color);
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
      text-decoration: underline;
    }
  }

  &__long_items {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}
