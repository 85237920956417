.title {
  font-size: 14px;
  font-weight: 500;
}

.packaging_tooltip {
  color: #031926;
  font-size: 12px;
  font-weight: 400;

  &__container {
    color: var(--Flexipill-Black-500, #031926);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    display: flex;
    margin-top: 16px;
    align-items: center;
  }

  &__type {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    padding-left: 2px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }
}

.shipping_tooltip {
  color: #031926;
  font-size: 10px;
  font-weight: 400;
}

.bill_details_container {
  border-radius: 8px;
  border: 1px solid var(--Flexipill-Grey-100, #e8e8e8);
  padding: 8px 12px 0;
}

.bill_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;

  &__title {
    color: var(--Platinum-rx-Black, #031926);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px; /* 131.25% */
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &__value_container {
    display: flex;
    gap: 5px;
    font-size: 14px;

    &__value {
      font-weight: 500;
      font-size: 14px;

      &__span {
        text-decoration: line-through;
        padding-left: 4px;
      }
    }
  }
}

.original_shipping_charges {
  text-decoration: line-through;
}

.total_pay {
  display: block;
  font-weight: 500;
  line-height: 1.75;
  width: 100%;

  .totalAmount {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-size: 14px;
  }

  &__value {
    font-size: 14px;
  }
}

.payment_mode {
  display: flex;
  justify-content: space-between;
  margin-top: 5px; // Adjust as needed

  &__title {
    font-weight: 500;
    margin-bottom: 5px;
    font-size: 14px;
  }

  &__value {
    font-size: 14px; // Adjust as needed
  }
}

.bill_middle_line {
  border-top: 1px dashed #a8a8a8;
  flex: auto;
  margin: 0 10px;
  place-self: center;
}

.add_more,
.free_delivery {
  place-items: center;
  border-radius: 9px;
  background: #fffbdb;
  padding: 4px;
  text-align: center;
  font-size: 12px !important;
  margin-bottom: 16px !important;
}

.add_more {
  span {
    color: #219653;
  }
}

.order_edit {
  display: flex;
  padding: 4px 8px;
  margin-bottom: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Platinumrx-Light-yellow, #fff1cd);
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;

  &__link {
    color: var(--primary-color, #fc5a5a);
  }
}

.partialPayemtDetails {
  padding: 0 8px;
  margin-bottom: 6px;
}

.partialPayemtDetails__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: var(--Dark-grey, #68757d);
}
.accordion {
  &__expand_icon {
    margin-right: -12px !important;
  }
}

.total_savings,
.total_pay_normal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-weight: 500;
  line-height: 1.75;
  padding-top: 8px;

  &__title {
    font-size: 14px;
  }

  &__value {
    font-size: 14px;
  }
}

.total_savings {
  margin-bottom: 8px;
  padding-top: 0px;

  &__value {
    color: #219653;
  }
}
