.details_container {
  padding: 12px 0;
  background-color: var(--white, #fff);
  border-radius: 8px;
}

.pickSKU {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.sku_swiper {
  position: relative;
}

.sku_slide {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  padding: 0 16px 160px 16px !important;

  &__grid {
    width: 100%;
  }

  &__basic {
    width: 100%;
    border: 1px solid var(--primary-color);
    border-radius: 12px;
    &__name,
    &__qty,
    &__jit {
      padding: 16px;
      display: flex;
      gap: 8px;
      justify-content: space-between;
    }
    &__name {
      border-radius: 11px 11px 0 0;
      background-color: var(--primary-color);
      color: var(--white, #fff);
    }
    &__qty {
      border-radius: 0 0 12px 12px;
    }
  }
}

.order_info {
  border-radius: 8px;
  border: 1px solid var(--Flexipill-Grey-100, #e8e8e8);
  padding: 8px 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.item {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  &__title {
    color: #6d7478;
  }
  &__value {
    font-weight: 500;
  }
}

.accordion {
  border: 1px solid #e8e8e8;
}

.header {
  display: flex;
  justify-content: space-between;
  color: var(--black);
}

.bottomButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: fixed;
  bottom: 0;
  width: calc(100% - 16px);
  background-color: var(--white, #fff) !important;
  z-index: 999;
  padding: 16px 0;
}

.dialog {
  padding: 16px;
  width: 280px;
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
}

.submit_btn {
  position: fixed;
  right: 16px;
  bottom: 16px;
  display: flex;
  gap: 16px;
}

.issue_raised {
  color: red;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  animation: fadeInOut 2s infinite;
}

.all_picked {
  text-align: center;
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: 500;
  color: #4caf50; /* Green color for visibility */
  animation: fadeInOut 2s infinite; /* Updated animation for fade in and out effect */
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.go_back {
  align-self: flex-end;
}

.issueItem {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e8e8e8;
  padding: 16px;
  border-radius: 8px;
}

.issues {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
}

.drugName {
  font-weight: 500;
  font-weight: 18px;
}

.reason {
  color: red;
  font-weight: 500;
  font-size: 14px;
}

.drugCode {
  margin-bottom: 6px;
  color: #6d7478;
  font-size: 14px;
}

.statusIndicators {
  overflow: scroll;
  display: flex;
  padding: 10px 0;
  height: 40px;
}

.statusIndicator {
  margin: 0 5px; /* Space between each indicator */
  font-size: 24px; /* Adjust size as needed */
  position: relative; /* Ensure positioning for the zoom effect */
}

.zoomEffect {
  transform: scale(1.5); /* Scale up the icon */
  transition: transform 0.2s ease; /* Smooth transition */
  z-index: 1; /* Ensure it appears above other icons */
}

.rackCode {
  margin: 8px 0;
  font-size: 18px;
  span {
    font-weight: 500;
  }
}
