:root {
  --primary-color: #fc5a5a;
  --Platinum-rx-Black: #031926;
  --light-pink: #ffebeb;
  --Platinum-sec-Red: #ffefef;
  --secondary-color: #1d8f3b;
  --tertiary-color: #ebf5eb;
  --deep-navy-blue: #031926;
  --black: #000;
  --white: #fff;
  --grey-61: #9c9c9c;
  --light-gray: #f6f6f6;
  --dark-violet: #361271;
  --charcoal-gray: #1f1f1f;
  --Platinum-Success-900: #509854;
  --Platinum-Error-900: #d60e0e;
  --grey-background: #fafafa;
  --grey-border-color: #e5e5e5;
  --PlatinumRX-Secondary-100: #cac3ff;
  --PlatinumRX-Secondary-900: #2f209c;
  --Platinum-RX-Grey-100: #ebebeb;
  --Platinum-seconary: #009595;
  --Platinum-Dark-grey: #6d7478;
  --Disabled-grey: #9f9f9f;
  --grey-2: #f5f5f5;
  --grey-3: #fbfbfb; /* Light mode default */
  --home-white: #fff;
  --light-grey: #d0d3da;
  --activity-dark-grey-text: #333;
  --activity-light-grey-text: #888;
}

[data-theme='dark'] {
  --primary-color: #8b2c2c;
  --Platinum-rx-Black: #ffffff;
  --light-pink: #5c3f3f;
  --Platinum-sec-Red: #3a3a3a;
  --secondary-color: #34c759;
  --tertiary-color: #1e1e1e;
  --deep-navy-blue: #000000;
  --black: #fff;
  --white: #000;
  --grey-61: #c0c0c0;
  --light-gray: #1a1a1a;
  --dark-violet: #4e3c8f;
  --charcoal-gray: #e2e2e2;
  --anti-charoal-grey: #1f1f1f;
  --Platinum-Success-900: #40a055;
  --Platinum-Error-900: #ff6b6b;
  --grey-background: #101010;
  --grey-border-color: #292929;
  --PlatinumRX-Secondary-100: #8a8eff;
  --PlatinumRX-Secondary-900: #4133bf;
  --Platinum-RX-Grey-100: #3c3c3c;
  --Platinum-seconary: #33d3d3;
  --Platinum-Dark-grey: #929292;
  --Disabled-grey: #606060;
  --grey-2: #1a1a1a;
  --grey-3: #2d2d2d; /* Light mode default */
  --home-white: #292929;
  --light-grey: #6d7478;
  --activity-dark-grey-text: #eeeded;
  --activity-light-grey-text: #a2a0a0;
}
