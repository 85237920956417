.details_container {
  padding: 12px 0;
  background-color: var(--white, #fff);
  border-radius: 8px;
  max-width: 500px;
}

.status_icon {
  margin-top: 2px;
}

.accordion_container {
  display: flex;
  gap: 12px;
  border-radius: 8px;
  padding: 12px;
}

.accordion {
  height: fit-content;
  border: 1px solid var(--primary-color, #fc5a5a);
  border-radius: 8px;
  &__expand_icon {
    color: var(--black, #000);
  }

  &__btn_container {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &__cancel_button {
    width: -moz-fit-content;
    width: fit-content;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.25px;
    cursor: pointer;
    color: var(--black, #000);
    background-color: unset;
    border: none;
    padding: 0;
  }

  &__cancel_button:hover {
    color: var(--primary-color, #fc5a5a);
  }

  &__disabled {
    color: var(--Disabled-grey, #9f9f9f);
    cursor: not-allowed;
  }

  &__disabled:hover {
    color: var(--Disabled-grey, #9f9f9f);
  }

  &__tooltip {
    &__message {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.25px;
      color: var(--black, #000);
    }

    &__icon {
      color: var(--Platinum-Dark-grey, #6d7478);
      cursor: pointer;
    }
  }
}

.main {
  display: flex;
  flex-direction: column;
  gap: 6px;
  border: 1px solid #6d7478;
  padding: 12px;
  border-radius: 8px;
}

.delivery_to {
  display: flex;
  gap: 6px;
  align-items: flex-end;
}

span {
  font-weight: 600;
}

.delivery_to_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
