.items_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.rx_icon {
  position: absolute;
  bottom: 0px;
  right: 0;
  display: flex;
  gap: 4px;
  display: flex;
  padding: 10px 4px;
  justify-content: center;
  align-items: center;
  gap: 2.435px;
  border-radius: 4px;
  background: var(--Disabled-grey, #9f9f9f);
  font-size: 14px;
  color: #fff;
  line-height: 0px;
}

.ReplaceMent__icon {
  font-size: 20px;
  margin: 0 4px;
  cursor: pointer;
}
.item {
  font-size: 14px;
  border-radius: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.25px;
  border-radius: 8px;
  border: 1px solid var(--Platinum-RX-Grey-100, #ebebeb);
  background: #fafafa;
  margin-bottom: 12px;

  &__flex_container {
    gap: 8px;
    padding: 8px;
    display: flex;
    justify-content: flex-start;
  }

  &__details {
    display: flex;
    gap: 8px;
    width: 100%;
  }

  &__img {
    position: relative;
    height: fit-content !important;
    &__image {
      width: 60px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 60px;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
      background-color: #fff;
    }

    &__tooltips {
      display: flex;
      gap: 2px;
      margin-top: 4px;
    }
  }

  &__add_btn {
    position: absolute;
    right: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__content {
    display: flex;
    flex-grow: 1;
  }

  &__texts {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__btn_container {
      display: flex;
      gap: 4px;
      justify-content: space-between;
    }

    .drug_name {
      font-weight: 500;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .manufacturer_details {
      display: flex;
      gap: 5px 0;
      color: var(--Platinum-Dark-grey, #6d7478);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.25px;
      flex-wrap: wrap;

      .vertical_divider {
        width: 1.5px;
        margin: auto 4px;
        height: 11px;
        background-color: #d9d9d9;
      }
    }

    .price_container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__info {
        display: flex;
        gap: 6px;
        align-items: center;
      }

      &__discounted {
        color: #219653;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.15px;
      }

      &__mrp {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.25px;
        span {
          text-decoration: line-through;
        }
      }
    }

    .coupon_discount {
      background-color: #f6fff3;
      display: flex;
      align-items: center;
      gap: 10px;
      color: #219653;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

.tooltip {
  color: #686868;
  &__content {
    color: #031926;
    font-size: 10px;
    font-weight: 400;
  }
  &__icon {
    cursor: pointer;
  }
}

.horizontal_divider {
  width: 100%;
  height: 1px;
  background-color: var(--grey-border-color, #e5e5e5);
}

.loader {
  background: #fafafa;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border-radius: 16px;
  align-items: flex-start;

  &:last-child {
    border: none;
  }

  &__details {
    display: flex;
    gap: 16px;
  }

  &__img {
    width: 80px;
    height: 80px;
    background-color: #fff;
  }

  &__add_btn {
    width: 64px;
    padding: 12px 0;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    gap: 2px;

    .drug_name {
      width: 160px;
    }

    .manufacturer_details {
      width: 160px;
      display: flex;
      gap: 5px 0;
      .detail {
        width: 65px;
      }
      .vertical_divider {
        width: 1.5px;
        margin: auto 4px;
        height: 11px;
        background-color: #d9d9d9;
      }
    }

    .price_container {
      display: flex;
      gap: 10px;
      align-items: center;
      width: 135px;
    }

    .coupon_discount {
      width: 120px;
    }
  }
}

.discountPercentage {
  border-radius: 4px;
  background: var(--Platinum-Success-900, #509854);
  color: var(--Platinum-rx-white, #fff);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 1px 4px 0 4px;
}

.chips_container {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 4px;
  .chip {
    display: flex;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--Platinum-RX-Grey-100, #ebebeb);
    background: #ebebeb;
    color: var(--Dark-grey, #6d7478);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}
