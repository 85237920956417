.accordion {
  border: 1px solid #e8e8e8;
}

.unfocusedContent {
  opacity: 0.5; /* Make the content appear grayed out */
  pointer-events: none; /* Disable interactions */
  filter: blur(2px); /* Optional: Add a slight blur effect */
  transition: opacity 0.3s ease, filter 0.3s ease; /* Smooth transition */
}
