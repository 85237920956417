.modal_padding {
  padding: 16px 16px;
  width: 740px;
  height: 360px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.inputs {
  display: flex;
  width: 100%;
  gap: 16px;

  &__box {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 16px;
    border: 1px solid var(--Platinum-RX-Grey-100, #ebebeb);
    background: var(--Background-Grey, #fafafa);
    width: 100%;
    height: 140px;
    cursor: pointer;
  }

  &__box_selected {
    border-color: var(--primary-color, #fc5a5a);
  }
}

.uploads {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 16px;

  &__title {
    margin-bottom: 12px;
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__rxs_container {
    margin: 48px 0 0 0;
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    width: 100%;
  }
}

.add_more {
  cursor: pointer;
  display: flex;
  width: 80px;
  height: 80px;
  padding: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  background: var(--Platinum-sec-Red, #ffefef);
  color: var(--primary-color, #fc5a5a);
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  &__icon {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    height: auto;
    padding: 12px 8px;
    margin-top: 8px;
  }
}

.upload_icon {
  width: 80px;
  height: 80px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-right: 16px;
  }
}

.disabled_btn {
  background: var(--Platinum-RX-Grey-100, #ebebeb);
  color: var(--Platinum-RX-Grey-200, #b3b3b3);
  cursor: not-allowed;
}

.file_preview {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--Platinum-RX-Grey-100, #ebebeb);
  border-radius: 8px;
  padding: 16px;
}
