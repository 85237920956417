.modal_padding {
  padding: 16px 16px;
}

.pending_order_listing tbody tr {
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr !important;
}

.pending_order_listing thead tr {
  grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr !important;
}

.sku_details tbody tr {
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr !important;
}

.sku_details thead tr {
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr !important;
}

.view_modal_button {
  border-radius: 8px;
  background: var(--primary-color, #fc5a5a);
  padding: 10px;
  color: var(--white);
  width: -moz-fit-content;
  width: fit-content;
}
