.zoom-drawer-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  height: fit-content;

  
  .zoom-drawer-zoomed-img {
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
  
  .drawerImage {
    width: auto;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
    max-height: 400px;
  }
  
  .zoom-drawer-footer {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    height: max-content;
    scrollbar-width: none;
  }
  
  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 8px;
  }
  
  .image img {
    object-fit: contain;
    max-width: 80px;
    height: auto;
    max-height: 80px;
  }
  
  .swiper-nav-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: 100%;
  }
  
  .swiper-nav-dots-active circle {
    fill: #414141;
  }
  
  .details {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  
  .zoom-modal-footer {
    bottom: 8px;
    left: 30%;
    position: absolute;
    display: flex;
    gap: 10px;
    width: fit-content;
    width: -moz-fit-content;
    max-width: 100%;
    margin: auto;
    overflow-x: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .zoom-modal-footer-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 8px;
    border: 2px solid #EFEFEF;
      @media (max-width: 768px){
        width: 24px;
        height: 24px;
      }
    }
    
    .zoom-modal-footer-image img {
      width: 100%;
      height: auto;
      max-height: 64px;
      border-radius: 8px;
      object-fit: contain;
      @media (max-width: 768px){
        max-height: 24px;
      }
    }
    
    .zoom-modal-footer-image-active {
      border: 2px solid #6D7478;
      width: 100px;
      height: 64px;
      @media (max-width: 768px){
        width: 56px;
        height: 24px;
      }
    }
}