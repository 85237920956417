.modal_padding {
  padding: 16px 16px;
}

.po_sections_padding {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.po_submit_buttion {
  width: '100%';
  margin-top: '10px';
  color: 'white';
  font-weight: '600';
  font-size: '13px';
  padding: '8px';
  border-radius: '4px';
  background: '#FC5A5A';
  cursor: 'pointer';
}

.procurement_listing tbody tr {
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.procurement_listing thead tr {
  grid-template-columns: 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
}

.pending_orders tbody tr {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.pending_orders thead tr {
  grid-template-columns: 1fr 1fr 1fr !important;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.end_message {
  text-align: center;
}

.po_input {
  border-radius: '15px';
  height: '25px';
  padding-left: '20px';
}

.view_modal_button {
  border-radius: 8px;
  background: var(--primary-color, #fc5a5a);
  padding: 10px;
  color: var(--white);
  width: -moz-fit-content;
  width: fit-content;
}

.input_container {
  margin-top: 8px;
}

.submit_btn {
  width: -moz-fit-content;
  width: fit-content;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .fileInfo {
    margin-top: 20px;
    margin-bottom: 10px;

    p {
      font-weight: bold;
    }

    .submitButton {
      margin-top: 10px;
    }
  }

  .uploadButton {
    background-color: #fc5a5a;
    color: var(--white);
  }
}

.uploadContainer {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sampleFileLink {
  text-wrap: nowrap;
}

.confirmDialog {
  padding: 24px;
}
