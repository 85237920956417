.zoom-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  overflow: hidden;

  .zoom-modal-drug-name {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }

  .zoom-modal-zoomed-image {
    height: 70%;
    max-width: 700px;
    max-height: 310px;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .zoom-modal-zoomed-image div {
    margin: auto;
  }

  .modalImage {
    width: auto;
    max-width: 700px;
    height: auto;
    max-height: 275px;
  }

  .zoom-modal-zoom-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .zoom-modal-footer {
    display: flex;
    gap: 10px;
    width: fit-content;
    width: -moz-fit-content;
    max-width: 100%;
    margin: auto;
    overflow-x: auto;
  }

  .zoom-modal-footer-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 8px;
  }

  .zoom-modal-footer-image img {
    width: 100%;
    height: auto;
    max-height: 78px;
    border-radius: 8px;
    object-fit: contain;
  }

  .zoom-modal-footer-image-active {
    border: 1px solid #6d7478;
  }

  .hr {
    border-bottom: 2px solid #d9d9d9;
    width: 100%;
  }

  .itemDetails {
    display: flex;
    align-items: center;
  }

  .name {
    color: #000;
    font-size: 16px;

    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 20px;
  }

  .priceContainer {
    display: flex;
    gap: 6px;
    align-items: center;
    margin-top: 4px;
  }

  .price {
    color: #000;

    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 20px;
  }

  .discount {
    color: #509854;

    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 17px;
  }

  .originalPrice {
    color: #68757d;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.25px;
    line-height: 16px;

    span {
      text-decoration: line-through;
    }
  }

  .addToCartButton {
    margin-left: 15px;
  }

  .tools {
    border-radius: 20px;
    padding: 2px 5px;
    border: 1px solid var(--grey-61, #9c9c9c);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    box-shadow: 0px 0px 1px grey;

    svg {
      color: grey;
      cursor: pointer;
    }
  }
}
