.filtersContainer {
  display: flex;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.datesContainer {
  display: flex;
  gap: 10px;
  margin-top: -8px;
}
