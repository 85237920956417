.change-cart-btn {
  border: 1px solid #fc5a5a;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: #f34d4d;
  position: relative !important;
  max-width: 86px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px !important;
  height: 30px !important;
  cursor: pointer;
}

.quantity {
  width: 36px;
  height: 30px !important;
  background-color: #ffefef;
  border-radius: 8px 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #f34d4d;
}

.arrow svg {
  transform: rotate(90deg);
  width: 20px;
  height: 12px;
}

.arrow svg path {
  fill: white;
}

.num-dropdown {
  width: 198px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  overflow-y: scroll;
  scrollbar-width: none;
  top: 31px;
  z-index: 2;
  box-shadow: 0px 0px 12px 0px #0000004d;
}

.quantity-list {
  width: 100%;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-bottom: 1px solid #ebebeb;
}

.quantity-list svg {
  height: 20px;
}

.add-button button {
  background-color: #fc5a5a !important;
  color: white !important;
  border-radius: 8px !important;
}

@media (max-width: 450px) {
  .change-cart-btn {
    max-width: 66px;
  }

  .arrow {
    width: 28px;
    height: 24px;
  }

  .quantity {
    width: 36px;
    height: 24px;
  }

  .num-dropdown {
    width: 64px;
    top: 25px;
  }
}

.overlay {
  z-index: 1;
  background-color: transparent;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.add_btn {
  display: flex;
  align-items: center;
  gap: 12px;
}

.extraText {
  color: var(--Dark-grey, #6d7478);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px; /* 142.857% */
}

.change_cart_btn {
  display: flex;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--Disabled-grey, #9f9f9f);
  min-width: 120px;
  cursor: pointer;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 30px;
}

.quantity {
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
}

.arrow svg {
  width: 18px;
  height: 18px;
}

.arrow svg path {
  fill: #fc5a5a;
}

.quantity-list {
  width: 100%;
  height: 36px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  border-bottom: 1px solid #ebebeb;
}

.quantity-list svg {
  height: 20px;
}

.modal_header {
  position: fixed;
  color: var(--Dark-grey, #6d7478);
  width: 180px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 8px 8px 0px 0px;
  z-index: 0;
  background: var(--Platinum-RX-Grey-100, #ebebeb) !important;
  padding: 12px;
  &__text {
    margin-left: 8px;
    margin-top: 4px;
  }
}

.open_dropdown {
  transform: rotate(180deg);
  transition: all 0.3s ease;
}
