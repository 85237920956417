.price_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__info {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  &__discounted {
    color: #219653;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  &__mrp {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.25px;
    span {
      text-decoration: line-through;
    }
  }
}

.discountPercentage {
  border-radius: 4px;
  background: var(--Platinum-Success-900, #509854);
  color: var(--Platinum-rx-white, #fff);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  padding: 1px 4px 0 4px;
}
