.my-swiper {

  
  .swiper-button-next::after {
    font-size: 20px;
    font-weight: bold;
    z-index: 0;
  }
  
  .swiper-button-prev::after {
    font-size: 20px;
    font-weight: bold;
    z-index: 0;
  }
  
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    z-index: 1;
    cursor: pointer;
    color: #6c6969;
    border-radius: 50%;
    margin-left: auto;
    width: 40px;
    height: 40px;
    @media (max-width: 768px) {
      transform: scale(75%);
    }
  }

  .swiper-button-next {
    position: absolute;
    top: 50%;
    z-index: 1;
    cursor: pointer;
    color: #6c6969;
    border-radius: 50%;
    margin-right: auto;
    width: 40px;
    height: 40px;
    @media (max-width: 768px) {
      transform: scale(75%);
    }
  }
}