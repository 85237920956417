.fullFilled {
  font-size: 12px;
  background: none;
  border: 1px solid;
  padding: 4px 8px;
  border-radius: 20px;
  cursor: pointer !important;
}

.fullFilled_not_done {
  border-color: var(--primary-color);
  color: var(--primary-color);
  cursor: pointer !important;
}

.fullFilled_done {
  border-color: green;
  color: green;
  cursor: not-allowed !important;
}

.modal_padding {
  padding: 32px;
  width: 700px;
}

.note {
  margin-bottom: 10px; // Add margin below the note
}

.noteText {
  color: #666;
  font-size: 16px;
  margin-bottom: 10px; // Additional margin if needed
}

.confirmDialog {
  padding: 24px;
}
