.formContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: white;
}

.formGroup {
  margin-bottom: 12px;

  label {
    .required {
      color: red;
      margin-left: 2px;
    }
  }
}

.dateTimeGroup {
  display: flex;
  gap: 10px;

  > div {
    flex: 1;
  }
}

.btnContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.rightContainer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.saveButton {
  align-self: flex-end;
}

.dialogContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}