.patient_details {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 8px;
  gap: 8px;

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.25px;
  }

  &__user_name {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.25px;
  }

  &__our_message {
    color: var(--PlatinumRx-Waring, #f8a72d);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  &__item {
    display: flex;
    gap: 8px;
    align-items: center;

    &__name {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.25px;
    }
  }

  &__value {
    font-size: 12px;
    font-weight: 400;
    color: var(--Platinum-Dark-grey, #68757d);
    line-height: 16px;
    letter-spacing: 0.25px;
  }

  &__address {
    font-size: 14px;
    font-weight: 400;
    color: var(--Platinum-Dark-grey, #68757d);
    line-height: 16px;
    letter-spacing: 0.25px;
  }
}

.divider {
  height: 14px;
  width: 1px;
  background: #d9d9d9;
  border-radius: 2px;
}

.partial_payment {
  color: var(--Dark-grey, #6d7478);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.25px;

  &__price {
    color: var(--Platinum-rx-Black, #031926);
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
  }
}
