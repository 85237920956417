.AcceptOrderMain {
  padding: 64px 16px 24px 16px;
}

.upperSection {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.userDetailsSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.searchBarSection {
  width: 100%;
  display: flex;
  gap: 16px;
}

.lowerSection {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  align-items: center;
}
