.home {
  padding: 70px 75px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--home-white);

  @media (max-width: 768px) {
    padding: 70px 8px;
  }
}

.filterHeader {
  border-radius: 12px;
  background: var(--Platinum-sec-Red, #ffefef);
  display: flex;
  padding: 16px;
  align-items: center;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }

  .dates {
    display: flex !important;
    gap: 12px;
  }

  .datePicker {
    @media (max-width: 768px) {
      min-width: 100px !important;
    }
  }
}

.listingPageTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid var(--Platinum-RX-Black-100, #cdd1d4);
  border-radius: 8px;

  .arrow {
    margin-left: 6px;
  }
}

.listingPageTableBody {
  display: flex;
  flex-direction: column;
}

.listingPageTableHead tr {
  border-radius: 8px 8px 0 0;
}

.listingPageTableHead tr,
.listingPageTableBody tr {
  width: inherit;
  display: grid;
  grid-template-columns: 0.5fr 1fr 1.5fr 1fr 1.5fr;
  gap: 2px;
  justify-content: space-between;
  background: var(--grey-3);
  text-align: center;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1.5fr 1fr;
  }
}

.listingPageTableHead tr th {
  background: var(--anti-charoal-grey);
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.listingPageTableBody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--Platinum-rx-Black, #031926);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px;
}

.splitHead tr,
.splitBody tr {
  grid-template-columns: 0.3fr 1fr 1.5fr;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1.5fr;
  }
}

.listingPageTable .orderId {
  color: var(--primary-color, #fc5a5a) !important;
  text-decoration: underline;
  width: unset;
}

.border1 {
  border-radius: 8px 0 0 0;
}

.border2 {
  border-radius: 0 8px 0 0;
}

.border3 {
  border-radius: 0 0 8px 0;
}

.border4 {
  border-radius: 0 0 0 8px;
}

.odd td {
  background-color: var(--white) !important;
}

.pharmacist {
  position: relative;
}

.doctor {
  position: relative;
}

.openAssign {
  border-radius: 4px;
  border: 1px solid var(--primary-color, #fc5a5a);
  color: var(--Disabled-grey, #9f9f9f) !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 18px !important;
}

.buttonContainer {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
  &__ {
    display: flex;
    gap: 10px;
  }
}

.active_btn {
  background-color: var(--white) !important;
  color: #fc5a5a !important;
  border-bottom: 2px solid #fc5a5a !important;
  border-radius: 0px !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 64px 0 20px 0;
}

.scanButton {
  padding: 10px 20px;
  background-color: #fc5a5a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.scanner_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: black;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 640px;
  height: 480px;
}

.scanner_view {
  width: 100%;
  height: calc(100% - 40px); // Account for close button

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.closeButton {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
}
