.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  position: relative;
}

.lightIcon,
.darkIcon {
  position: absolute;
  pointer-events: none; /* Added to make the icons non-clickable */
}

.lightIcon {
  left: 6px;
  top: 8px;
}

.darkIcon {
  right: 7px;
  top: 7px;
}
