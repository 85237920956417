.timelineContainer {
  padding: 16px;
  border-radius: 8px;
}

.timelineGroup {
  margin-bottom: 24px;
}

.timelineDate {
  padding-bottom: 8px;
  padding-left: 100px;
  font-size: 18px !important;
  font-weight: 500;
  color: var(--black);
}

.timelineEvents {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.timelineEvent {
  display: flex;
  align-items: center;
  position: relative;
}

.timelineTime {
  width: 80px;
  padding-right: 12px;
  text-align: right;
  color: var(--activity-light-grey-text);
}

.timelineConnector {
  position: relative;
  margin: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connectorDot {
  width: 16px;
  height: 16px;
  padding: 4px;
  border-radius: 50%;
  background: #1976d2;
  position: relative;
  z-index: 2;
}

.timelineContent {
  flex: 1;
  min-width: 0;
}

.eventDetailsWrapper {
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--home-white) !important;
}

.eventHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  cursor: pointer;
}

.eventTitle {
  font-size: 14px;
  font-weight: 400;
  color: var(--activity-dark-grey-text);
}

.eventSubtitle {
  font-size: 12px;
  font-weight: 400;
  color: var(--activity-light-grey-text);
}

.expandButton {
  color: var(--activity-light-grey-text);
}

.eventContent {
  padding: 8px;
  font-size: 12px !important;
  color: var(--Platinum-Dark-grey) !important;
  border-top: 1px solid var(--grey-border-color);
}
.tableHeader {
  font-size: 12px;
  color: #565656;
}
.link {
  color: #1976d2;
  text-decoration: underline;
  cursor: pointer;
}
