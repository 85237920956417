.btns_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  background: var(--white, #fff) !important;
  padding: 8px 12px;
  border-radius: 12px;
  z-index: 999;
  position: absolute;
  margin: -72px auto 0 auto;
}

.scanButton {
  padding: 10px 20px;
  background-color: #fc5a5a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.submit__btn {
  padding: 10px 20px;
  background-color: #fc5a5a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.scanner_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background: black;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 640px;
  height: 480px;
}

.scanner_view {
  width: 100%;
  height: calc(100% - 40px);
  zoom: 20%;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.closeButton {
  position: absolute;
  top: 16px;
  right: -32px;
  transform: translateX(-50%);
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c82333;
  }
}

.manualInputs {
  display: flex;
  width: 100%;
  gap: 16px;
}

.main {
  position: relative;
}
