.details_container {
  padding: 12px 0;
  background-color: var(--white, #fff);
  border-radius: 8px;
}

.content {
  display: flex;
  gap: 16px;
}

.rx_icon {
  border-radius: 4px;
  background: #d2d2d2;
  color: var(--Platinum-Dark-grey, #6d7478);
  padding: 0 3px;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
}

.drug_name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item_container {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  border: 2px solid var(--white, #fff);
  font-size: 14px;
  border-radius: 12px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.25px;
  &:last-child {
    border: none;
  }
}

.item_img {
  min-width: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 80px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  background-color: var(--white);
}

.item_qty {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.img_container {
  display: flex;
  margin: 5px 0;
}

.manufacturer_name {
  color: var(--Platinum-Dark-grey, #6d7478);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.vertical_divider {
  width: 1.5px;
  margin: auto 4px;
  height: 11px;
  background-color: #d9d9d9;
}

.price_container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.discounted_price {
  color: #219653;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.mrp_price {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.25px;
  text-decoration: line-through;
}

.horizontal_divider {
  width: 100%;
  height: 1px;
  background-color: var(--grey-border-color, #e5e5e5);
}

.issues_container {
  display: flex;
  font-size: 10px;
  gap: 4px;
}
