.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px;
}

.title {
  color: var(--Dark-grey, #6d7478);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.cards_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.cards {
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #ececec;
  background-color: #fff;
  color: #000;
  .title {
    margin-bottom: unset;
  }
  .desc {
    color: var(--Platinum-rx-Black, #031926);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.total_prx {
  display: flex;
  align-items: center;
  .value {
    color: var(--Platinum-rx-Black, #031926);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

.check_box {
  margin: -8px -4px -8px -8px;
  svg {
    color: var(--primary-color, #fc5a5a);
  }
}

.shipping_tooltip {
  color: var(--Flexipill-Black-500, #031926);
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  width: 245px;
}

.Tooltip {
  color: var(--dark-grey, #68757d);
}

.tooltip {
  &__icon {
    color: var(--dark-grey, #68757d);
    margin: 0 0 -2px 2px;
  }
  &__content {
    color: var(--Flexipill-Black-500, #031926);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }
}

.mobileOnly {
  @media (min-width: 768px) {
    display: none;
  }
}

.coupon_box_bottom {
  border-radius: 8px;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px 16px 4px 16px;
  min-width: 300px;
  background: linear-gradient(
    180deg,
    #ffd4d4 0%,
    rgba(248, 214, 214, 0) 144.17%
  );
}

.coupon_box_bottom div {
  color: var(--Platinum-rx-Black, #031926);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.25px;
  cursor: pointer;
}

.inc_height {
  height: auto;
}

.dec_height {
  height: 45px;
}

.hide {
  display: none;
  transition: all 1s ease;
}

.show {
  width: 100%;
  display: block;
  transition: all 1s ease;
  padding-bottom: 12px;
}

.rotate {
  transform: rotate(180deg);
  transition: all 0.3s linear;
}

.rotateReverse {
  transform: rotate(0deg);
  transition: all 0.3s linear;
}

.cash_icon {
  width: 26px;
  height: 26px;
}

.coin_icon {
  margin-top: 4px;
  width: 24px;
  height: 24px;
}

.left_cont {
  display: flex;
  gap: 8px;
}
