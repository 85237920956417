.container {
  padding: 20px;
  margin-top: 48px;
}

.headerMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e8e8e8;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.userHeader {
  display: flex;
  align-items: center;
}

.taskDetails {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 16px;
  font-weight: normal;
  gap: 4px;
  span {
    font-weight: 500;
  }
}

.userAvatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #ff6b00;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin-right: 16px;
}

.userInfo h2 {
  margin: 0;
  font-size: 18px;
}

.userMeta {
  display: flex;
  gap: 16px;
  color: #666;
  font-size: 14px;
}

.timeline {
  padding: 20px;
}

.timelineGroup {
  margin-bottom: 24px;
}

.timelineDate {
  color: #666;
  margin-bottom: 16px;
}

.timelineEvent {
  display: flex;
  margin-bottom: 16px;
  position: relative;
  align-items: center;
}

.timelineTime {
  width: 100px;
  color: #666;
  font-size: 14px;
}

.timelineIcon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f0f0f0;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timelineContent {
  flex: 1;
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  padding: 16px;
}

.timelineContent h4 {
  margin: 0 0 8px 0;
  font-size: 16px;
}

.timelineConnector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 24px 0 0;
  height: 100%;
  position: relative;
}

.connectorDot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #1976d2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.connectorLine {
  width: 2px;
  height: 100%;
  background: #e0e0e0;
  position: absolute;
  top: 0;
  bottom: 0;
}

.eventContent {
  margin-top: 8px;
}

.searchEvent,
.cartEvent,
.cartAddEvent,
.leadStageEvent {
  color: #666;
  font-size: 0.875rem;
}

.leadStageEvent .changedBy {
  margin-top: 8px;
  font-size: 0.75rem;
  color: #888;
}

.eventHeader {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  h4 {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: #1976d2;
  }
}

.tableHeader {
  font-weight: 500 !important;
  width: 200px;
  color: #666;
  font-size: 0.875rem;
}

.conversationTab {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  padding: 16px;
  cursor: not-allowed;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 14px;
  margin-top: 16px;
}

.dnc {
  color: red;
  font-weight: 500;
  font-size: 24px;
  border: 2px solid red;
  border-radius: 12px;
  padding: 4px 8px;
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}
