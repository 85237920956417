@import './_variables';

html,
body {
  padding: 0;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  background-color: var(--home-white);
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.main {
  margin: 64px 16px 24px 16px;
}

.container {
  padding: 1.25% 8%;

  @media (max-width: 768px) {
    padding: 3.5% 4.5%;
  }
}

// input

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

.fullWidth {
  width: 100%;
}

// Accordion

.MuiAccordion-root {
  box-shadow: none !important;
  color: #031926 !important;
  text-align: left;
}

.MuiAccordion-root:before {
  background-color: unset !important;
}

.MuiAccordionSummary-expandIconWrapper {
  color: #f44d4d !important;
}

.MuiAccordion-rounded.Mui-expanded {
  margin: 0 !important;
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
  margin-top: 8px !important;
}

.faqAccordionContainer .MuiAccordion-root {
  border-radius: 8px !important;
  margin-bottom: 12px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px !important;
}

.MuiDialog-paper {
  border-radius: 24px !important;
}

.NoInteractionModal {
  .MuiDialog-paper {
    border-radius: 12px !important;
  }
}

.video-section-accordion {
  .MuiButtonBase-root {
    min-height: 48px !important;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin-top: 0px !important;
  }
}

.quantity_modal {
  margin-top: 25vh;
  height: 370px;

  .MuiDialog-paper {
    border-radius: 8px 8px 0px 0px !important;
  }

  .MuiDialogContent-root {
    padding: 0px;
  }
}

.MuiDialogContent-root {
  border: none !important;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorBottom {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.cartModalContainer .MuiDialog-paperScrollPaper {
  position: absolute;
  max-width: 475px !important;
  top: 55px;
  right: 0;
  margin-right: 0;
  border-radius: 0px 0px 16px 16px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
}

.cartModalContainer .MuiModal-backdrop {
  background-color: #0003;
}

.howToModalContainer .MuiDialog-paper {
  background: linear-gradient(180deg, #fff5f5 0%, #fff 100%);
}

input[type='search'] {
  -webkit-appearance: none !important;
  appearance: none !important;
}

// tabs

.MuiTabs-root {
  width: -moz-fit-content !important;
  width: fit-content !important;
  background-color: #fff !important;

  @media (max-width: 768px) {
    width: unset !important;
  }
}

.MuiTab-root {
  font-size: 16px !important;
  text-transform: none !important;
  font-weight: 400 !important;
  color: #000 !important;
}

// tooltip
.MuiTooltip-tooltip {
  border-radius: 8px !important;
  padding: 8px !important;
  background-color: #fff !important;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
}

.MuiTooltip-popper {
  max-width: 280px;
  z-index: 999 !important;
}

.MuiTooltip-popper div {
  background-color: var(--primary-color) !important;
}

.MuiTooltip-arrow {
  color: #fff !important;
}

.Mui-selected {
  // border-radius: 8px 0px 0px 8px !important;
  color: #f44d4d !important;
  border-bottom: 4px solid #f44d4d !important;
  background-color: #fddbdb !important;
}

.loginModal .MuiDialog-paper {
  border-radius: 8px !important;
}

// Radio select
.addressPatSelection {
  width: 100%;

  .MuiFormGroup-root {
    .selectedItemLabel {
      box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .MuiFormControlLabel-root {
    border-radius: 12px;
    cursor: pointer;
    background: #fafafa;
    padding: 16px;
    display: flex;
    margin: 8px 0;
    gap: 10px;
  }

  .MuiFormControlLabel-label {
    width: 100%;
  }
}

.MuiFormHelperText-root {
  color: var(--error-red, #d60e0e) !important;
  font-size: 12px !important;
  line-height: 16px; /* 133.333% */
  margin: 0px 0px 0px 4px !important;
}

.profile-header-mobile-sub {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  padding: 4%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fbf5f3;
  margin-bottom: 5%;

  b {
    margin: auto;
    padding-right: 4%;
  }

  p {
    cursor: pointer;
  }
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  padding: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #818589;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  display: none;
}

.action-btn {
  color: white !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  background: #8cb92d !important;
  padding: 2px 15px !important;
  border-radius: 10px !important;
}

.mTop50 {
  margin-top: 50px;
}

.MuiDialogContent-root {
  padding: 0px !important;
}

.button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.home {
  padding: 70px 75px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--home-white);
  color: var(--black);
  @media (max-width: 768px) {
    padding: 70px 8px;
  }
}

.filter-header {
  border-radius: 12px;
  background: var(--Platinum-sec-Red, #ffefef);
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
  border: 1px solid var(--Platinum-RX-Black-100, #cdd1d4);
  border-radius: 8px;

  .arrow {
    margin-left: 6px;
  }
}

.table-body {
  display: flex;
  flex-direction: column;
}

.table-head tr {
  border-radius: 8px 8px 0 0;
}

.table-head tr,
.table-body tr {
  width: inherit;
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 2px;
  justify-content: space-between;
  background: #fbfbfb;
  text-align: center;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1.5fr 1fr;
  }
}

.table-head tr th {
  background: #e2e2e2;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  font-size: 18px;
  font-weight: 500;
}

.table-body tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--Platinum-rx-Black, #031926);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 8px;
}

.border-1 {
  border-radius: 8px 0 0 0;
}

.border-2 {
  border-radius: 0 8px 0 0;
}

.border-3 {
  border-radius: 0 0 8px 0;
}

.border-4 {
  border-radius: 0 0 0 8px;
}

.odd td {
  background-color: var(--white) !important;
}

.prx-btn {
  background: var(--primary-color, #fc5a5a);
  color: #fff;
  border: none;
  padding: 0px;
  cursor: pointer;
  outline: inherit;
  padding: 8px;
  border-radius: 8px;
}

.prx-disabled-btn {
  border: none;
  padding: 0px;
  outline: inherit;
  padding: 8px;
  border-radius: 8px;
  background: var(--Platinum-RX-Grey-100, #ebebeb);
  color: var(--Platinum-RX-Grey-200, #b3b3b3);
  cursor: not-allowed;
}

.MuiDataGrid-row:hover {
  background-color: 'skyblue';
  // color: "red"
}

.MuiDataGrid-root {
  background-color: var(--white);
}
.onlyMobile {
  @media (max-width: 768px) {
    display: none;
  }
}

.link {
  color: #031926;
  border-bottom: 1px solid red;
}

.bigModal {
  .MuiDialog-paperScrollPaper {
    width: 1000px;
    min-width: 1000px;
    margin: auto;
    padding: 0;
  }
}

.cancelDrawer {
  z-index: 1400 !important;
}

.quantity_modal {
  margin-top: 25vh;
  height: 370px;

  .MuiDialog-paper {
    border-radius: 8px 8px 0px 0px !important;
  }

  .MuiDialogContent-root {
    padding: 0px;
  }
}
