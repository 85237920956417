.loading_container {
  text-align: center;
  margin: 30px 0;
}

.order_details_page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.25% 8%;

  @media (max-width: 768px) {
    padding: 3.5% 4.5%;
  }
}

.order_details_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 32px);
  padding: 16px !important;
  gap: 16px;
  @media (min-width: 768px) {
    flex-direction: row;
    row-gap: 16px;
    margin-top: 32px;
    gap: 0;
  }
}

.order_status_details {
  background-color: #fafafa;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
}

.order_details {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.saving_section {
  display: flex;
  padding: 8px 16px;
  background: linear-gradient(269deg, #e5ffde -9.99%, #d6ffcb 102.86%);
  border-radius: 8px;
  gap: 8px;
  align-items: center;

  &__img {
    font-size: 26px;
  }

  &__text {
    color: #007729;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

.loyalty_saving_section {
  background: #eeecff !important;
}

.loyalty_saving_text {
  color: #000 !important;
}

.cancelled_section {
  margin-left: 10px;
  padding: 0px 5px;
}

.cancelled_text {
  font-size: 20px;
  color: #fc5a5a;
}

.cancelled_details {
  display: flex;
  font-size: 16px;
  color: #6d7478;
  margin-top: 7px;
}

.cancelled_details p {
  margin-left: 6px;
}

.cancelled_reason {
  color: #6d747d;
}

.cancelled_reason.mark {
  border: 1px solid #fc5a5a;
  margin: 16px 16px 16px 0px;
}

.billing_address_container {
  background-color: #fafafa;
  padding: 12px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  @media (min-width: 768px) {
    padding: 16px;
  }
}

.bill_details_container {
  min-width: unset;
  width: unset;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (min-width: 768px) {
    background-color: #fff;
    padding: 0 16px;
    min-width: 460px;
  }
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  position: relative;
  margin: 24px 0;
  width: 680px;
  align-items: center;

  .item {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 30%;

    .image {
      height: 30px;
      width: 30px;
    }

    .sheild {
      height: 27px;
      width: 21px;
    }

    .nabl {
      height: 30px;
      width: 30px;
      margin-left: 25px;
    }
    .text {
      font-size: 14px;
    }
  }

  .divider {
    height: 100%;
    width: 2px;
    border-radius: 8px;
    background-color: #e5e5e5;
  }
}

.order_info {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  color: var(--Platinum-rx-Black, #031926);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;
  align-items: center;

  &__pending {
    color: var(--PlatinumRx-Waring, #f8a72d);
  }

  @media (min-width: 768px) {
    padding: 0 16px;
  }

  &__btn_container {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  &__order_id {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__track_btn {
    padding: 2px 8px;
    cursor: pointer;
    color: var(--Platinum-rx-Primary, #fc5a5a);
    border: 1px solid var(--Platinum-rx-Primary, #fc5a5a);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 6px;
    height: 28px;
  }

  &__status_icon {
    width: 15px;
    height: 12px;
    margin-right: 2px;
    @media (min-width: 768px) {
      margin-top: 2px;
    }
  }

  &__reason {
    color: var(--Dark-grey, #6d7478);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.25px;
  }
}

.dates {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.25px;

  &__placed_on {
    color: var(--Platinum-rx-Black, #031926);
  }

  &__tentative_delivery {
    color: var(--Platinum-seconary, #009595);
  }
}

.help_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.25px;

  &__sharing_btns {
    display: flex;
    gap: 16px;
    color: var(--white);
    width: 100%;
    max-width: 380px;
    justify-content: center;
  }

  &__sharing_btn {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    flex: 1 0 0;
    border-radius: 8px;
    cursor: pointer;
  }

  &__whatsapp {
    background: linear-gradient(93deg, #1aa925 -3.28%, #16ac22 102.43%);
    &__icon {
      width: 24px;
      height: 24px;
    }
  }

  &__call_us {
    background: var(--Platinum-rx-Primary, #fc5a5a);
  }
}

.order_status {
  @media (min-width: 768px) {
    border: 1px solid #e5e5e5;
  }
}

.cashback {
  border-radius: 8px;
  border: 1px solid var(--Platinum-rx-Primary, #fc5a5a);
  background: var(--Platinum-sec-Red, #ffefef);
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--Dark-grey, #6d7478);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.return_container {
  width: 100%;
  border: 1px solid red;
  padding: 16px;
  border-radius: 8px;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.zoho_status {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.download_order_edited_pdf {
  color: var(--Platinum-rx-Primary, #fc5a5a);
  font-size: 14px;
  font-weight: 500;
}
