.medicine-container{
    width: 90%;
    display: grid;
    grid-template-columns: 8fr 1fr;
    justify-content: space-around;
    align-items: center;
    margin-top: 8px;
}

.single-medicine-card{
    margin: auto;
    margin-top: 5px;
    /* display: grid; */
    /* grid-template-columns: 1fr 1.2fr; */
    width: calc(100% - 60px);
    gap: 10px;
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0 0 2px black;
}

.single-medicine-card div{
    display: flex;
    gap: 10px;
}

.hero-img-container{
    width: 150px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.medicine-details{
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #686868;
}

.hero-img-container img{
    max-width: 100%;
    max-height: 100%;
}

.medicine-name{
    color: blue;
    font-size: 13px;
    
}