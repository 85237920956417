.order_page {
  padding: 60px 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
  &__box_code {
    display: flex;
    justify-content: space-between;
  }
}
