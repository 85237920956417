.select_status {
  .arrow {
    transform: rotate(90deg);
  }

  .open_dropdown {
    display: flex;
    align-items: center;
    gap: 8px;
    border: 1px solid #e0e0e0;
    background-color: var(--white);
    padding: 8px 16px;
    border-radius: 30px;
    width: min-content;
  }

  .change_cart_btn {
    position: relative;
  }

  .quantity_select {
    margin: 2px 0;
    cursor: pointer;
    font-size: 12px;
    background-color: #f1f1f1;
    padding: 2px 0;
    padding-left: 16px;
    border-radius: 4px;
    span {
      color: var(--Platinum-Dark-grey);
    }
  }

  .quantity_select:hover {
    background-color: #e0e0e0;
  }

  .quantity_select_active {
    background-color: #ffcdcd;
  }

  .num_dropdown {
    width: 100%;
    z-index: 2;
    border: 1px solid #e0e0e0;
    background-color: var(--white);
    border-radius: 8px;
    padding: 4px;
    margin-top: 4px;
  }
}
