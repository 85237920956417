.customerContainer {
  padding: 2rem;
  background-color: #f5f5f5; // Light background for the dashboard
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.customerTitle {
  margin-bottom: 1.5rem;
  font-weight: bold;
}

.customerCard {
  overflow: scroll !important;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  &__buttons {
    display: flex;
    gap: 1rem;
  }
  &__info {
    &__icons {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
      margin: 8px 0;
    }
    &__icon {
      display: flex;
      align-items: center;
      background-color: #f5f5f5;
      padding: 0.5rem;
      border-radius: 8px;
      width: fit-content;
    }
    &__email {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

.customerCard {
  border-radius: 8px;
  height: 320px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

.customerCard_po {
  margin: -8px -16px;
  max-height: 320px;
  box-shadow: none;
  overflow: scroll;
  @media (min-width: 768px) {
    height: auto;
  }
}

.aboveContainer {
  display: flex;
  gap: 1rem;
  &__select {
    width: 300px;
  }
}

.customerGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.address {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.addressDialog {
  padding: 8px 16px;
}

.locality_container {
  position: relative;
}

.locality_suggestions {
  // position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 10000;
  border-radius: 4px;
  color: #000;
  font-size: 12px;
  line-height: 16px;
}

.suggestion_item {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  &:hover {
    background-color: #f5f5f5;
  }
}

.pincode_container {
  display: flex;
  gap: 8px;
  width: 100%;
  overflow: hidden;
  align-items: center;
}

.current_location {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--Platinum-rx-Primary, #fc5a5a);
  background: var(--Platinum-sec-Red, #ffefef);
  color: var(--Platinum-rx-Primary, #fc5a5a) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  gap: 8px;
  color: #000;
  font-size: 12px;
  font-weight: 500;
  text-wrap: nowrap;
  line-height: 20px;

  &__icon {
    width: 20px;
    height: 20px;
  }
}

.saving_header {
  background: var(--Background-Grey, #fafafa);
  padding: 8px 16px;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  line-height: 10px;
}

.total_saving_header {
  display: flex;
  justify-content: space-between;

  &__icon {
    font-size: 12px;
    margin-bottom: -2px;
  }

  &__coin_icon {
    font-size: 12px;
    margin-bottom: -2px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  &__price {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-self: flex-end;
    text-align: right;
  }
}

.header {
  display: flex;
  padding: 8px 16px;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  align-self: stretch;
  background: var(--Platinum-rx-white, #fff);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.25px;

  .btn {
    width: 100%;
    border-bottom: 1px solid #e2e2e2;
    padding: 6px 16px;
    text-wrap: nowrap;
    cursor: pointer;
    span {
      font-weight: 500;
    }
  }
  .active {
    background: var(--Platinum-sec-Red, #ffefef);
    border-bottom: 1px solid var(--primary-color, #fc5a5a);
    transition: all 0.3s ease-in-out;
  }

  .coin_icon {
    margin-bottom: -2px;
    font-size: 16px;
  }
  .icon {
    font-size: 16px;
    margin-bottom: -2px;
  }
}

.content {
  padding: 16px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.end_message {
  text-align: center;
}

.no_transactions {
  text-align: center;
  padding: 16px;
}

.patientInfo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.addressContent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__text {
    max-width: 340px;
    overflow: scroll;
    text-wrap: nowrap;
  }
}
