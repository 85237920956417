.default_home {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;

  h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
    font-size: 2.5rem;
  }

  .navigation_grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    padding: 1rem;
    width: 100%;
  }

  .nav_card {
    background: white;
    border-radius: 8px;
    padding: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    cursor: pointer;
    text-align: center;
    min-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
      background-color: #f8f9fa;
    }

    h2 {
      margin: 0;
      color: #2c3e50;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}
