.statusDisplay {
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 8px;
  border-radius: 8px;
  font-size: 10px !important;
}

.container {
  position: relative;
}

.statusOptions {
  background: var(--white, #fff) !important;
  position: absolute;
  top: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
  right: 0;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px;
  border-radius: 4px;
}

.options {
  border: 1px solid;
  border-radius: 12px;
  padding: 8px;
  color: #333;
  font-size: 10px !important;
}
